import { TEACHER_FIELDS } from "../constants/teacher-fields";

const requiredFields = ["EMAIL", "NAME"];
const completeFields = [
	"LANGUAGE",
	"SCHOOL",
	"GRADES",
	"SUBJECTS",
];

const gradesRequiringSubjects = ["6", "7", "8", "9", "10", "11", "12"];

export const getIsTeacherMissingRequiredFields = (userInfo = {}) => {
	const missingFields = [];
	requiredFields.forEach((field) => {
		if (!userInfo[TEACHER_FIELDS[field]]) missingFields.push(field);
	});
	return missingFields.length > 0;
};

export const getIsTeacherMissingCompleteFields = (userInfo = {}) => {
	const missingFields = [];
	completeFields.forEach((field) => {
		if (field === "SUBJECTS") {
			const grades = userInfo[TEACHER_FIELDS["GRADES"]] || [];
			const requiresSubjects = grades.some((grade) =>
				gradesRequiringSubjects.includes(grade)
			);
			if (requiresSubjects && !userInfo[TEACHER_FIELDS[field]]) {
				missingFields.push(field);
			}
		} else if (!userInfo[TEACHER_FIELDS[field]]) {
			missingFields.push(field);
		}
	});
	return missingFields.length > 0;
};

export const getIsNewTeacher = (userInfo = null) =>
	!userInfo || getIsTeacherMissingRequiredFields(userInfo);

export const getIsIncompleteTeacher = (userInfo = null) =>
	userInfo &&
	!getIsNewTeacher(userInfo) &&
	getIsTeacherMissingCompleteFields(userInfo);
