import React, { Fragment, useEffect, useRef, useState } from "react";
import {
	FormGroup,
	Input,
	InputGroup,
	Row,
	Col,
	Button,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown
} from "reactstrap";
import airtable from "../../airtables";
import LoadingBar from "../../components/LoadingBar";
import { toast } from "react-toastify";
import { logEvent } from "firebase/analytics";
import Gap from "../../components/Gap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import SearchIcon from "@mui/icons-material/Search";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputSearch from "@mui/material/Input";
import CircularProgress from "@mui/material/CircularProgress";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	sortlist,
	culturalGroups,
	SearchableGrades,
	WellBeingLinks,
	FieldsOfStudy
} from "../../utils/constants";
import { Pagination } from "../../components/Pagination";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { analytics } from "../../firebase";
import { useLocation } from "react-router-dom";
import SessionInfo from "../../components/SessionInfo";
import StepSlider from "../../components/StepSliderSearch";
import classNames from "classnames";
import "rc-slider/assets/index.css";
import { getActionMenuForSearch, getDisplayInfosForSearch } from "../SessionInfoDisplay";
import BookSessionModal from "../modals/BookSession";
import SendToTeacher from "../modals/SendToTeacher";
import AssignTagForSession from "../modals/AssignTagForSession";

const Screen = () => {
	const { t } = useTranslation();
	const { totalSubjects, communities, appLocale, userInfo } = useSelector((state) => state.appInfo);
	const location = useLocation();
	const isFirstRef = useRef(true);
	const { userType } = useSelector((state) => state.appInfo);

	const [loading, setLoading] = useState(false);
	const [sortInfo, setSortInfo] = useState(sortlist[0]);
	const [indigenousGroups, setIndigenousGroups] = useState([]);
	const [sessions, setSessions] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [sessionsToShow, setSessionsToShow] = useState([]);
	const [pagesize] = useState(15);
	const [grades, setGrades] = useState(
		SearchableGrades.map((g) => ({ name: g })),
	);
	const [subjects, setSubjects] = useState([]);
	const [wellBeingLinks, setWellBeingLinks] = useState(
		WellBeingLinks.map((l) => ({ name: l })),
	);
	const [search, setSearch] = useState("");
	const [searchCalled, setSearchCalled] = useState(false);
	const [language, setLanguage] = useState([]);
	const [length, setLength] = useState([]);
	const [searchstatus, setSearchStatus] = useState([]);
	const [nosupplies, setNoSupplies] = useState(false);
	const [indigenousCommunity, setIndigenousCommunity] = useState("");
	const [culturalGroup, setCulturalGroup] = useState("");
	const [applyButton, showApplyButton] = useState(false);
	const [isGradesExpanded, setIsGradesExpanded] = useState(false);
	const [isSubjectsExpanded, setIsSubjectsExpanded] = useState(false);
	const [runSearch, setRunSearch] = useState(0);

	const [subjectsExpanded, setSubjectsExpanded] = useState(true);
	const [gradesExpanded, setGradesExpanded] = useState(false);
	const [wellBeingLinksExpanded, setWellBeingLinksExpanded] = useState(false);
	const [lengthExpanded, setLengthExpanded] = useState(false);
	const [languageExpanded, setLanguageExpanded] = useState(false);
	const [supExpanded, setSupExpanded] = useState(false);
	const [igsExpanded, setIgsExpanded] = useState(false);
	const [statusExpanded, setStatusExpanded] = useState(false);
	const [culturalGroupExpanded, setCulturalGroupExpanded] = useState(true);
	const [communityExpanded, setCommunityExpanded] = useState(false);
	const [creditsExpanded, setCreditsExpanded] = useState(false);
	const [filterCredits, setFilterCredits] = useState(60);

	const [showBookSessionModal, setShowBookSessionModal] = useState(false);
	const [activeSession, setActiveSession] = useState(null);

	const [showSendToTeacherModal, setShowSendToTeacherModal] = useState(false);
	const [activeSessionForSTT, setActiveSessionForSTT] = useState(null);

	const [showAssignTagModal, setShowAssignTagModal] = useState(false);
	const [activeSessionForAT, setActiveSessionForAT] = useState(null);

	const [fieldsOfStudy, setFieldsOfStudy] = useState([]);
	const [fieldsOfStudyExpanded, setFieldsOfStudyExpanded] = useState(false);

	const MAX_CREDITS = 60;

	useEffect(() => {
		window.document.title = "Search - Connected North";
	}, []);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);

		if (!totalSubjects || !totalSubjects.length) return;
		if (!communities || !communities.length) return;
		if (!isFirstRef.current) return;
		isFirstRef.current = false;

		var firstNationCommunities = communities.filter(
			(community) => community.Type === "First Nations",
		);
		var inuitCommunities = communities.filter(
			(community) => community.Type === "Inuit",
		);
		var metisCommunities = communities.filter(
			(community) => community.Type === "Métis",
		);

		let ingGroups = [
			{ name: "First Nations", group: firstNationCommunities },
			{ name: "Inuit", group: inuitCommunities },
			{ name: "Métis", group: metisCommunities },
		];

		setIndigenousGroups(ingGroups);

		const q = queryParams.get("q");
		if (q) {
			setSearch(q);
		}

		const sbs = totalSubjects.filter(
			sub => (sub["Subject"] !== "Premium Session" && sub["Subject"] !== "Therapy (SLP/OT)")
		).map((s) => ({ ...s }))
		setSubjects(sbs);

		const subs = queryParams.getAll("subjects");
		if (subs && subs.length) {
			let sbs = [...subjects];
			sbs.forEach((sub, i) => {
				if (subs.indexOf(sub["Subject"]) >= 0) {
					sbs[i].checked = true;
				}
			});
			setSubjects(sbs);
			setSubjectsExpanded(true);
		} else setSubjectsExpanded(false);

		const gds = queryParams.getAll("grades");
		if (gds && gds.length) {
			let gs = [...grades];
			gs.forEach((grade, i) => {
				if (gds.indexOf(grade.name) >= 0) {
					gs[i].checked = true;
				}
			});
			setGrades(gs);
			setGradesExpanded(true);
		} else setGradesExpanded(false);

		const wbls = queryParams.getAll("wellbeinglinks");
		if (wbls && wbls.length) {
			let wbs = [...wellBeingLinks];
			wbs.forEach((w, i) => {
				if (wbls.indexOf(w.name) >= 0) {
					wbs[i].checked = true;
				}
			});
			setWellBeingLinks(wbs);
			setWellBeingLinksExpanded(true);
		} else setWellBeingLinksExpanded(false);

		let lens = queryParams.getAll("length");
		if (lens && lens.length) {
			lens = lens.map((l) => parseInt(l));
			setLength(lens);
			setLengthExpanded(true);
		} else setLengthExpanded(false);

		let searchstatus = queryParams.getAll("searchstatus");
		if (searchstatus && searchstatus.length) {
			setSearchStatus(searchstatus);
			setStatusExpanded(true);
		} else setStatusExpanded(false);

		let nosup = queryParams.getAll("nosupplies");
		if (nosup && nosup.length) {
			setNoSupplies(true);
			setSupExpanded(true);
		} else setSupExpanded(false);

		let langs = queryParams.getAll("language");
		if (langs && langs.length) {
			setLanguage(langs);
			setLanguageExpanded(true);
		} else setLanguageExpanded(false);

		let credits = queryParams.get("credits");
		if (credits) {
			setFilterCredits(parseInt(credits));
			setCreditsExpanded(true);
		} else setCreditsExpanded(false);

		let igs = queryParams.getAll("indigenousGroups");
		if (igs && igs.length) {
			let ings = [...ingGroups];
			if (igs.indexOf("First Nations") >= 0) {
				ings[0].checked = true;
			}
			if (igs.indexOf("Inuit") >= 0) {
				ings[1].checked = true;
			}
			if (igs.indexOf("Métis") >= 0) {
				ings[2].checked = true;
			}
			setIndigenousGroups(ings);
			setIgsExpanded(true);
		} else setIgsExpanded(false);

		if (ingGroups[0].checked) {
			let cg = queryParams.get("culturalGroup");
			if (cg) {
				setCulturalGroup(cg);
				setCulturalGroupExpanded(true);
			} else setCulturalGroupExpanded(false);
		}

		let ic = queryParams.get("indigenousCommunity");
		if (ic) {
			setIndigenousCommunity(ic);
			setCommunityExpanded(true);
		} else setCommunityExpanded(false);

		let pn = queryParams.get("pageno");
		if (pn) setCurrentPage(parseInt(pn) - 1);

		setFieldsOfStudy(FieldsOfStudy.map(field => ({ 
			id: field.id,
			name: field.en,  // Always use English name
			localizedName: appLocale === 'fr' ? field.fr : field.en,  // Use this for display
			checked: false 
		})));

		const fos = queryParams.getAll("fieldsOfStudy");
		if (fos && fos.length) {
			setFieldsOfStudy(prevFields => prevFields.map(field => ({
				...field,
				checked: fos.includes(field.id)
			})));
			setFieldsOfStudyExpanded(true);
		} else setFieldsOfStudyExpanded(false);

		setRunSearch((r) => r + 1);
	}, [totalSubjects, communities, grades, location.search, subjects, wellBeingLinks, appLocale]);

	useEffect(() => {
		if (runSearch) {
			let filter = false;
			if (search) filter = true;

			const checkedSubjects = subjects.filter(s => s.checked);
			if (checkedSubjects && checkedSubjects.length) filter = true;

			const checkedGrades = grades.filter(g => g.checked);
			if (checkedGrades && checkedGrades.length) filter = true;

			const checkedWBLs = wellBeingLinks.filter(w => w.checked);
			if (checkedWBLs && checkedWBLs.length) filter = true;

			if (length && length.length) filter = true;
			if (searchstatus && searchstatus.length) filter = true;
			if (nosupplies && nosupplies.length) filter = true;
			if (language && language.length) filter = true;
			if (filterCredits !== MAX_CREDITS) filter = true;

			const checkedIGs = indigenousGroups.filter(g => g.checked);
			if (checkedIGs && checkedIGs.length) filter = true;

			if (culturalGroup) filter = true;
			if (indigenousCommunity) filter = true;

			const checkedFieldsOfStudy = fieldsOfStudy.filter(f => f.checked);
			if (checkedFieldsOfStudy && checkedFieldsOfStudy.length) filter = true;

			if (filter) {
				onSearch();
			} else {
				setSubjectsExpanded(true);
				loadUpcomingSessions();
			}
		}
	}, [runSearch]); // eslint-disable-line react-hooks/exhaustive-deps

	const loadUpcomingSessions = async (sort) => {
		try {
			const si = sort ? sort : sortInfo;
			setLoading(true);
			const sessList = await airtable.providerSessions.getUpcomingSessions(si.sort);
			setSessions(sessList);
			setSessionsToShow(sessList.slice(currentPage, (currentPage + 1) * pagesize));
			setLoading(false);
		} catch (error) {
			setLoading(false);
			toast.error(error.toString());
		}
	};

	const onSearch = async () => {
		setCurrentPage(0);  // Reset to the first page
		setLoading(true);
	
		// Collecting filter data
		const filterGrades = grades.filter((grade) => grade.checked);
		const filterSubjects = subjects.filter((subject) => subject.checked);
		const filterWellBeingLinks = wellBeingLinks.filter((linkItem) => linkItem.checked);
		const filterIndigenousGroups = indigenousGroups.filter((ig) => ig.checked);
		const filterFieldsOfStudy = fieldsOfStudy.filter((field) => field.checked);
	
		const searchStr = search ? search.trim() : "";
	
		const params = {
			search: searchStr ? searchStr : null,
			language: language,
			length: length,
			filterGrades: filterGrades.length === 0 ? null : filterGrades,
			filterSubject: filterSubjects.length === 0 ? null : filterSubjects,
			filterWellBeingLink: filterWellBeingLinks.length === 0 ? null : filterWellBeingLinks,
			filterCredits: filterCredits === MAX_CREDITS ? null : filterCredits,
			nosupplies: nosupplies,
			searchstatus: searchstatus ? searchstatus : null,
			filterIndigenousGroups: filterIndigenousGroups.length === 0 ? null : filterIndigenousGroups,
			culturalGroup: culturalGroup,
			indigenousCommunity: indigenousCommunity,
			filterFieldsOfStudy: filterFieldsOfStudy.length === 0 ? null : filterFieldsOfStudy,
		};
	
		try {
			// Fetching filtered sessions
			const { results, searchTerm } = await airtable.providerSessions.filterProviderSessions(
				params.search,
				params.language,
				params.length,
				params.filterGrades,
				params.filterSubject,
				params.filterWellBeingLink,
				filterIndigenousGroups,
				indigenousCommunity,
				culturalGroup,
				params.filterCredits,
				params.nosupplies,
				params.searchstatus,
				sortInfo.sort,
				userInfo.Cluster,
				params.filterFieldsOfStudy,
			);
	
			// Prioritize results if there's a search term
			const prioritizedResults = searchTerm 
				? airtable.providerSessions.prioritizeResults(results, searchTerm)
				: results;
	
			// Logging the search event to Firebase
			logEvent(analytics, "search", {
				query: searchStr,
				filters: {
					grades: filterGrades.map((g) => g.name),
					subjects: filterSubjects.map((s) => s.Subject),
					wellbeingLinks: filterWellBeingLinks.map((l) => l.name),
					indigenousGroups: filterIndigenousGroups.map((g) => g.name),
					fieldsOfStudy: filterFieldsOfStudy.map((f) => f.name),
					language: language,
					length: length,
					searchstatus: searchstatus,
					noSupplies: nosupplies,
					culturalGroup: culturalGroup,
					indigenousCommunity: indigenousCommunity,
					credits: filterCredits,
				},
				result: prioritizedResults.length,
			});
	
			setSessions(prioritizedResults);
			setSessionsToShow(prioritizedResults.slice(currentPage, (currentPage + 1) * pagesize));
			setSearchCalled(true);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			toast.error(error.toString());
		}
	};	

	const resort = (sort) => {
		setSortInfo(sort);
		if (searchCalled) setRunSearch((r) => r + 1);
		else loadUpcomingSessions(sort);
	};

	const investigateSearchParams = (pn) => {
		const params = new URLSearchParams();

		if (search) params.append("q", search);

		let sbs = subjects.filter((sub) => sub.checked);
		if (sbs && sbs.length) {
			sbs.forEach((s) => params.append("subjects", s["Subject"]));
		}

		let gs = grades.filter((g) => g.checked);
		if (gs && gs.length) {
			gs.forEach((g) => params.append("grades", g.name));
		}

		let wbs = wellBeingLinks.filter((w) => w.checked);
		if (wbs && wbs.length) {
			wbs.forEach((w) =>
				params.append("wellbeinglinks", w.name)
			);
		}

		if (length.length) {
			length.forEach((l) =>
				params.append("length", l)
			);
		}

		if (language.length) {
			language.forEach((l) =>
				params.append("language", l)
			);
		}

		let igs = indigenousGroups
			.filter((ig) => ig.checked)
			.map((g) => g.name);
		if (igs && igs.length) {
			igs.forEach((g) =>
				params.append("indigenousGroups", g)
			);
		}

		if (igs.indexOf("First Nations") >= 0 && culturalGroup)
			params.append("culturalGroup", culturalGroup);

		if (indigenousCommunity)
			params.append("indigenousCommunity", indigenousCommunity);

		if (nosupplies)
			params.append("nosupplies", 'true');

		if (filterCredits < MAX_CREDITS) {
			params.append("credits", filterCredits);
		}

		let fos = fieldsOfStudy.filter((f) => f.checked).map((f) => f.id);
		if (fos && fos.length) {
			fos.forEach((f) => params.append("fieldsOfStudy", f));
		}

		// Only append pageno if it's not a new search
		if (pn !== undefined && pn !== 0) {
			params.append("pageno", pn + 1);
		}

		const paramString = params.toString();
		if (paramString) {
			window.history.replaceState(null, "", `/cn/search?${paramString}`);
		} else {
			window.history.replaceState(null, "", `/cn/search`);
		}
	};

	const toggleLength = async (len) => {
		if (length.indexOf(len) >= 0) {
			setLength(length.filter((l) => l !== len));
		} else {
			setLength([...length, len]);
		}

		showApplyButton(true);
	};

	const toggleNoSupplies = async (nosup) => {
		setNoSupplies(!nosupplies);  // This will toggle the state between true and false
		showApplyButton(true);
	};

	const toggleLanguage = async (lang) => {
		if (language.indexOf(lang) >= 0) {
			setLanguage(language.filter((l) => l !== lang));
		} else {
			setLanguage([...language, lang]);
		}
		showApplyButton(true);
	};

	const toggleSearchStatus = (status) => {
		setSearchStatus((prevStatus) => {
			const currentIndex = prevStatus.indexOf(status);
			if (currentIndex === -1) {
				return [...prevStatus, status];
			} else {
				return prevStatus.filter((item) => item !== status);
			}
		});
		showApplyButton(true);
	};

	const renderFilters = () => {
		const SLICE_VALUE = 6;
		const subjectsList = !isSubjectsExpanded
			? subjects.slice(0, SLICE_VALUE)
			: subjects;
		const gradesList = !isGradesExpanded
			? grades.slice(0, SLICE_VALUE)
			: grades;

		var combinedCommunityOptions = [];
		for (var i = 0; i < indigenousGroups.length; i++) {
			if (indigenousGroups[i].checked)
				combinedCommunityOptions.push(indigenousGroups[i].group);
		}

		var communityOptionsForMenu = combinedCommunityOptions.length
			? combinedCommunityOptions.flat(1)
			: communities;

		return (
			<div className="search-bar">
				<Gap height={20} />
				<div
					className="search-accordian-group"
					style={{ paddingBottom: "1.5rem" }}
				>
					<Accordion
						expanded={subjectsExpanded}
						onChange={(_, expanded) =>
							setSubjectsExpanded(expanded)
						}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							{t("subject-areas")}
						</AccordionSummary>
						<AccordionDetails>
							<FormGroup>
								{subjectsList.map((subject, index) => {
									const subjectName =
										appLocale === "fr"
											? subject["Subject (FR)"]
											: subject["Subject"];
									if (!subjectName) return null;
									return (
										<div key={index}>
											<FormControlLabel
												control={
													<Checkbox
														sx={{
															paddingTop: 0,
															paddingBottom: 0,
														}}
														checked={
															subject.checked
																? true
																: false
														}
														onClick={() => {
															if (
																!subject.checked
															)
																subject.checked = false;
															let sbs = [
																...subjects,
															];
															sbs[index].checked =
																!subject.checked;
															setSubjects(sbs);
															showApplyButton(
																true,
															);
														}}
													/>
												}
												label={subjectName}
											/>
										</div>
									);
								})}
							</FormGroup>
							<Button
								color="link"
								className="more-btn"
								onClick={() =>
									setIsSubjectsExpanded(!isSubjectsExpanded)
								}
							>
								{!isSubjectsExpanded
									? `+ ${subjects.length - SLICE_VALUE} more`
									: "show less"}
							</Button>
						</AccordionDetails>
					</Accordion>
				</div>
				<div className="search-accordian-group">
					{userType === 'Team' && (
						<Accordion
							expanded={statusExpanded}
							onChange={(_, expanded) =>
								setStatusExpanded(expanded)
							}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								Status (Team-only)
							</AccordionSummary>
							<AccordionDetails>
								<FormGroup>
									<div>
										<FormControlLabel
											control={
												<Checkbox
													sx={{
														paddingTop: 0,
														paddingBottom: 0,
													}}
													checked={
														searchstatus.includes("Consider Another Option")
													}
													onClick={() =>
														toggleSearchStatus("Consider Another Option")
													}
												/>
											}
											label="Consider Another Option"
										/>
									</div>
									<div>
										<FormControlLabel
											control={
												<Checkbox
													sx={{
														paddingTop: 0,
														paddingBottom: 0,
													}}
													checked={
														searchstatus.includes("In Development")
													}
													onClick={() =>
														toggleSearchStatus("In Development")
													}
												/>
											}
											label="In Development"
										/>
									</div>
									<div>
										<FormControlLabel
											control={
												<Checkbox
													sx={{
														paddingTop: 0,
														paddingBottom: 0,
													}}
													checked={
														searchstatus.includes("Private")
													}
													onClick={() =>
														toggleSearchStatus("Private")
													}
												/>
											}
											label="Private"
										/>
									</div>
								</FormGroup>
							</AccordionDetails>
						</Accordion>
					)}
					<Accordion
						expanded={gradesExpanded}
						onChange={(_, expanded) => setGradesExpanded(expanded)}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							{t("grade-levels")}
						</AccordionSummary>
						<AccordionDetails>
							<FormGroup>
								{gradesList.map((grade, index) => (
									<div key={index}>
										<FormControlLabel
											control={
												<Checkbox
													checked={
														grade?.checked
															? true
															: false
													}
													sx={{
														paddingTop: 0,
														paddingBottom: 0,
													}}
													onClick={() => {
														if (!grade.checked)
															grade.checked = false;
														let gs = [...grades];
														gs[index].checked =
															!grade.checked;
														setGrades(gs);
														showApplyButton(true);
													}}
												/>
											}
											label={grade.name}
										/>
									</div>
								))}
							</FormGroup>
							<Button
								color="link"
								className="more-btn"
								onClick={() =>
									setIsGradesExpanded(!isGradesExpanded)
								}
							>
								{!isGradesExpanded
									? `+ ${grades.length - SLICE_VALUE} more`
									: "show less"}
							</Button>
						</AccordionDetails>
					</Accordion>

					<Accordion
						expanded={wellBeingLinksExpanded}
						onChange={(_, expanded) =>
							setWellBeingLinksExpanded(expanded)
						}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							{t("well-being-links")}
						</AccordionSummary>
						<AccordionDetails>
							<FormGroup>
								{wellBeingLinks.map((linkItem, index) => (
									<div key={index}>
										<FormControlLabel
											control={
												<Checkbox
													sx={{
														paddingTop: 0,
														paddingBottom: 0,
													}}
													checked={
														linkItem?.checked
															? true
															: false
													}
													onClick={() => {
														if (!linkItem.checked)
															linkItem.checked = false;
														let ws = [
															...wellBeingLinks,
														];
														ws[index].checked =
															!linkItem.checked;
														setWellBeingLinks(ws);
														showApplyButton(true);
													}}
												/>
											}
											label={t(linkItem["name"])}
										/>
									</div>
								))}
							</FormGroup>
						</AccordionDetails>
					</Accordion>

					<Accordion
						expanded={lengthExpanded}
						onChange={(_, expanded) => setLengthExpanded(expanded)}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							{t("length")}
						</AccordionSummary>
						<AccordionDetails>
							<FormGroup>
								<div>
									<FormControlLabel
										control={
											<Checkbox
												sx={{
													paddingTop: 0,
													paddingBottom: 0,
												}}
												checked={
													length.indexOf(30) >= 0
												}
												onClick={() => toggleLength(30)}
											/>
										}
										label="30 minutes"
									/>
								</div>
								<div>
									<FormControlLabel
										control={
											<Checkbox
												sx={{
													paddingTop: 0,
													paddingBottom: 0,
												}}
												checked={
													length.indexOf(45) >= 0
												}
												onClick={() => toggleLength(45)}
											/>
										}
										label="45 minutes"
									/>
								</div>
								<div>
									<FormControlLabel
										control={
											<Checkbox
												sx={{
													paddingTop: 0,
													paddingBottom: 0,
												}}
												checked={
													length.indexOf(60) >= 0
												}
												onClick={() => toggleLength(60)}
											/>
										}
										label="60 minutes"
									/>
								</div>
							</FormGroup>
						</AccordionDetails>
					</Accordion>

					<Accordion
						expanded={languageExpanded}
						onChange={(_, expanded) =>
							setLanguageExpanded(expanded)
						}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							{t("language")}
						</AccordionSummary>
						<AccordionDetails>
							<FormGroup>
								<div>
									<FormControlLabel
										control={
											<Checkbox
												sx={{
													paddingTop: 0,
													paddingBottom: 0,
												}}
												checked={
													language.indexOf(
														"English",
													) >= 0
												}
												onClick={() =>
													toggleLanguage("English")
												}
											/>
										}
										label="English"
									/>
								</div>
								<div>
									<FormControlLabel
										control={
											<Checkbox
												sx={{
													paddingTop: 0,
													paddingBottom: 0,
												}}
												checked={
													language.indexOf(
														"French",
													) >= 0
												}
												onClick={() =>
													toggleLanguage("French")
												}
											/>
										}
										label="French"
									/>
								</div>
							</FormGroup>
						</AccordionDetails>
					</Accordion>

					<Accordion
						expanded={igsExpanded}
						onChange={(_, expanded) => setIgsExpanded(expanded)}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							{t("indigenous-groups")}
						</AccordionSummary>
						<AccordionDetails>
							<FormGroup>
								{indigenousGroups &&
									indigenousGroups.length &&
									indigenousGroups.map((igroup, index) => (
										<div key={index}>
											<FormControlLabel
												control={
													<Checkbox
														sx={{
															paddingTop: 0,
															paddingBottom: 0,
														}}
														checked={
															igroup?.checked
																? true
																: false
														}
														onClick={() => {
															if (!igroup.checked)
																igroup.checked = false;
															let igs = [
																...indigenousGroups,
															];
															igs[index].checked =
																!igroup.checked;
															setIndigenousGroups(
																igs,
															);
															showApplyButton(
																true,
															);
														}}
													/>
												}
												label={igroup["name"]}
											/>
										</div>
									))}
							</FormGroup>
						</AccordionDetails>
					</Accordion>

					{indigenousGroups &&
						indigenousGroups.length &&
						indigenousGroups[0].checked ? ( // if 'First Nation' group is checked
						<Accordion
							expanded={culturalGroupExpanded}
							onChange={(_, expanded) =>
								setCulturalGroupExpanded(expanded)
							}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								{t("cultural-group")}
							</AccordionSummary>
							<AccordionDetails>
								<FormGroup>
									<Input
										type="select"
										id="culturalGroup"
										value={culturalGroup}
										className="filter-select"
										onChange={(e) => {
											setCulturalGroup(e.target.value);
											showApplyButton(true);
										}}
									>
										<option value="">{t("all")}</option>

										{culturalGroups.map((g, index) => (
											<option
												className="filter-dropdown"
												key={index}
												value={g}
											>
												{g}
											</option>
										))}
									</Input>
								</FormGroup>
							</AccordionDetails>
						</Accordion>
					) : null}

					<Accordion
						expanded={communityExpanded}
						onChange={(_, expanded) =>
							setCommunityExpanded(expanded)
						}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="indigenousCommunity-content"
							id="indigenous-community-header"
						>
							{t("community")}
						</AccordionSummary>
						<AccordionDetails>
							<FormGroup>
								<Input
									type="select"
									className="filter-select"
									id="indigenousCommunity"
									value={indigenousCommunity}
									onChange={(e) => {
										setIndigenousCommunity(e.target.value);
										showApplyButton(true);
									}}
								>
									<option value="">{t("none")}</option>
									{communityOptionsForMenu.map(
										(com, index) => (
											<option
												key={index}
												value={com.Name}
											>
												{com.Name}
											</option>
										),
									)}
								</Input>
							</FormGroup>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={supExpanded}
						onChange={(_, expanded) => setSupExpanded(expanded)}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							{t("supplies-required")}
						</AccordionSummary>
						<AccordionDetails>
							<FormGroup>
								<div>
									<FormControlLabel
										control={
											<Checkbox
												sx={{
													paddingTop: 0,
													paddingBottom: 0,
												}}
												checked={nosupplies}
												onChange={toggleNoSupplies}
											/>
										}
										label={t("supplies-required-check")}
									/>
								</div>
							</FormGroup>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={creditsExpanded}
						onChange={(_, expanded) =>
							setCreditsExpanded(expanded)
						}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="credits-content"
							id="credits-header"
						>
							{t("credits-required")}
						</AccordionSummary>
						<AccordionDetails>
							<FormGroup>
								<div style={{ paddingBottom: "2rem" }}>
									<StepSlider
										max={MAX_CREDITS}
										onChange={(e) => {
											setFilterCredits(e);
											showApplyButton(true);
										}}
										value={filterCredits}
									/>
								</div>
							</FormGroup>
						</AccordionDetails>
					</Accordion>

					<Accordion
						expanded={fieldsOfStudyExpanded}
						onChange={(_, expanded) =>
							setFieldsOfStudyExpanded(expanded)
						}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							{t("fields-of-study")}
						</AccordionSummary>
						<AccordionDetails>
							<FormGroup>
								{fieldsOfStudy.map((field, index) => (
									<div key={field.id}>
										<FormControlLabel
											control={
												<Checkbox
													checked={field.checked}
													sx={{
														paddingTop: 0,
														paddingBottom: 0,
													}}
													onClick={() => {
														setFieldsOfStudy(prevFields => prevFields.map(f => 
															f.id === field.id ? { ...f, checked: !f.checked } : f
														));
														showApplyButton(true);
													}}
												/>
											}
											label={field.localizedName}
										/>
									</div>
								))}
							</FormGroup>
						</AccordionDetails>
					</Accordion>
				</div>
				<Button
					color="primary"
					className={classNames({
						"not-activated": !applyButton,
					})}
					onClick={() => {
						setCurrentPage(0);  // Reset to the first page
						investigateSearchParams(0);  // Pass 0 to indicate a new search
						setRunSearch((r) => r + 1);
					}}
				>
					{t("apply-filter")}
				</Button>
			</div>
		);
	};

	const renderSearchInput = () => {
		return (
			<FormGroup style={{ width: "100%" }}>
				<InputGroup className="mb-4">
					<InputSearch
						type="text"
						size="large"
						variant="solid"
						placeholder={t("search-by-name")}
						aria-label={t("search-by-name")}
						disableUnderline={true}
						sx={{
							borderRadius: "1.625rem",
							width: "100%",
							color: "black",
							backgroundColor: "white",
							padding: ".25rem 0",
						}}
						startAdornment={
							<div style={{ padding: "0 1rem" }}>
								<SearchIcon />
							</div>
						}
						endAdornment={
							<div className="search-suffix">
								{search && (
									<IconButton
										disableRipple
										disableFocusRipple
										onClick={() => setSearch("")}
										style={{
											padding: 0,
											paddingRight: "10px",
										}}
									>
										<ClearIcon />
									</IconButton>
								)}
								<Button
									color="secondary"
									style={{
										borderRadius: "1.625rem",
										paddingBottom: ".15rem",
										paddingTop: ".25rem",
										textTransform: "none",
										fontWeight: "400",
									}}
									onClick={() => {
										setCurrentPage(0);  // Reset to the first page
										investigateSearchParams(0);  // Pass 0 to indicate a new search
										setRunSearch((r) => r + 1);
									}}
								>
									{t("search")}
								</Button>
							</div>
						}
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						onKeyDown={(event) => {
							if (event.keyCode === 13) {
								setCurrentPage(0);  // Reset to the first page
								investigateSearchParams(0);  // Pass 0 to indicate a new search
								setRunSearch((r) => r + 1);
							}
						}}
					/>
				</InputGroup>
			</FormGroup>
		);
	};

	const renderSortDropdown = () => {
		return sessions.length > 0 ? (
			<UncontrolledDropdown>
				<DropdownToggle caret color="white">
					{`${t("sort-by")}: ${appLocale === 'fr' ? sortInfo?.label_fr : sortInfo?.label}`}
				</DropdownToggle>
				<DropdownMenu>
					{sortlist.map((sort, i) => (
						<DropdownItem
							href="#pablo"
							onClick={() => resort(sort)}
							key={i}
						>
							{appLocale === 'fr' ? sort.label_fr : sort.label}
						</DropdownItem>
					))}
				</DropdownMenu>
			</UncontrolledDropdown>
		) : null;
	};

	const renderSessionCards = () => {
		return (
			sessions.length > 0 &&
			sessionsToShow.map((session, i) => (
				<div key={i}>
					<SessionInfo
						displayInfos={getDisplayInfosForSearch(session, userType)}
						actionMenu={getActionMenuForSearch(userType, userInfo)}
						session={session}
						sessionDetailLink={session.id}
						bookSession={() => {
							setActiveSession(session);
							setShowBookSessionModal(true);
						}}
						sendToTeacher={() => {
							setShowSendToTeacherModal(true);
							setActiveSessionForSTT(session)
						}}
						addToCollection={() => {
							setShowAssignTagModal(true)
							setActiveSessionForAT(session)
						}}
					/>
				</div>
			))
		);
	};

	const pageCount = Math.ceil(sessions.length / pagesize);

	useEffect(() => {
		setSessionsToShow(
			[...sessions.slice(
				currentPage * pagesize,
				(currentPage + 1) * pagesize,
			)]
		);
		window.scrollTo(0, 0);
	}, [currentPage, sessions, pagesize])

	return (
		<div
			className="main-container search-container"
			style={{ minHeight: "100vh" }}
		>
			<Row className="search-actions m-0 p-0 align-items-center justify-content-between">
				<Col className="search-actions-col1">
					<h1>{t("explore-sessions")}</h1>
				</Col>
				<Col
					xs="12"
					md="5"
					className="search-actions-credits text-md-right mt-1 mt-md-0"
				>
					{userType === "Teacher" ? (
						<>
							{t("my-booking-credits")}:{" "}
							{userInfo["Credit Balance"]}
						</>
					) : null}
				</Col>
			</Row>
			<Row className="m-0">
				<Fragment>
					<Col xs="12" md="3" className="justify-content-start">
						<div className="search-input-org d-md-none mt-4">
							{renderSearchInput()}
						</div>
						{renderFilters()}
					</Col>
					<Col md="9" className="search-input-org">
						<Row className="d-none d-md-flex pt-4">
							{renderSearchInput()}
						</Row>
						<Row style={{ justifyContent: "center" }}>
							{!loading ? (
								<div style={{ width: "100%" }}>
									{renderSortDropdown()}
									<div className="session-search-card-container">
										{renderSessionCards()}
									</div>
									<div className="paginate pb-4">
										{sessions.length > pagesize && (
											<Pagination
												page={currentPage}
												setPage={(p) => {
													investigateSearchParams(p);
													setCurrentPage(p);
												}}
												pageCount={pageCount}
											/>
										)}
									</div>
								</div>
							) : (
								<CircularProgress />
							)}
						</Row>
					</Col>
				</Fragment>
				{loading ? <LoadingBar /> : null}
			</Row>

			{
				activeSession && showBookSessionModal && (
					<BookSessionModal
						providerSessionId={activeSession.id}
						providerName={activeSession["Provider Name Text"][0]}
						providerId={activeSession["Provider"][0]}
						onToggle={() => {
							setShowBookSessionModal(false);
							setActiveSession(null);
						}}
					/>
				)
			}

			{!!activeSessionForSTT && showSendToTeacherModal && (
				<SendToTeacher
					data={activeSessionForSTT}
					onToggle={() => {
						setShowSendToTeacherModal(!showSendToTeacherModal)
						setActiveSessionForSTT(null);
					}}
					type="Session"
				/>
			)}

			{showAssignTagModal && !!activeSessionForAT && (
				<AssignTagForSession
					session={activeSessionForAT}
					onToggle={() => {
						setShowAssignTagModal(!showAssignTagModal)
						setActiveSessionForAT(null)
					}}
				/>
			)}
		</div>
	);
};

export default Screen;
