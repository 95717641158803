import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { useSelector } from 'react-redux';
import OverlayLoading from '../../components/OverlayLoading';
import airtable from '../../airtables';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import SessionList from './SessionList';

const Screen = () => {
  const { userType, userInfo } = useSelector(state => state.appInfo);
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);
  const [activeTab, setActiveTab] = useState('registered');
  const [rLoading, setRLoading] = useState(true);
  const [sLoading, setSLoading] = useState(true);
  const [pLoading, setPLoading] = useState(true);
  const location = useLocation();

  const [registeredSessions, setRegisteredSessions] = useState([]);
  const [studentSessions, setStudentSessions] = useState([]);
  const [professionalSessions, setProfessionalSessions] = useState([]);

  /*
  const [pdweekSessions, setPDWeekSessions] = useState([]);
  const [pdwLoading, setPDWLoading] = useState(true);
  const [vcfLoading, setVCFLoading] = useState(true);
  const [rrdsbiLoading, setRRDSBILoading] = useState(true);
  const [vcfSessions, setVCFSessions] = useState([]);
  const [rrdsbiSessions, setRRDSBISessions] = useState([]); */

  const [initSSLoaded, setInitSSLoaded] = useState(false);
  const [initialSetupDone, setInitialSetupDone] = useState(false);

  useEffect(() => {
    window.document.title = "Events - Connected North"
    refreshPage();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const refreshPage = async () => {
    // get registered sessions


    // get student sessions
    const schoolId = userInfo["School Name"] ? userInfo["School Name"][0] : "no-school";
    airtable.sessions.getStudentEvents().then(async (res) => {
      const sessList = [];
      for (var i = 0; i < res.length; i++) {
        if (userType !== 'Team' && res[i]["Cluster Event Restriction"]) {
          const clusters = await airtable.clusters.listByIds(res[i]["Cluster Event Restriction"]);
          const isAvailable = clusters.some(cluster => cluster["Schools"].indexOf(schoolId) >= 0)
          if (isAvailable) {
            sessList.push(res[i]);
          }
        } else {
          sessList.push(res[i]);
        }
      }
      setInitSSLoaded(true);
      setSLoading(false)
      setStudentSessions(sessList);
    }).catch(error => setSLoading(false));

    // get profession sessions
    airtable.sessions.getProfessionalLearning().then(async (res) => {
      const sessList = [];
      for (var i = 0; i < res.length; i++) {
        if (userType !== 'Team' && res[i]["Cluster Event Restriction"]) {
          const clusters = await airtable.clusters.listByIds(res[i]["Cluster Event Restriction"]);
          const isAvailable = clusters.some(cluster => cluster["Schools"].indexOf(schoolId) >= 0)
          if (isAvailable) {
            sessList.push(res[i]);
          }
        } else {
          sessList.push(res[i]);
        }
      }
      setPLoading(false);
      setProfessionalSessions(sessList);
    }).catch(error => setPLoading(false));

    /* 
    // get pdweek sessions
    airtable.sessions.getPDWeekSessions().then(async (res) => {
        const sessList = [];
        for (var i = 0; i < res.length; i++) {
            if (
                res[i]["Custom Session Title"] &&
                res[i]["Custom Session Title"].includes("PD Week 2024")
            ) {
                sessList.push(res[i]);
            }
        }
        setPDWLoading(false);
        setPDWeekSessions(sessList);
    }).catch(error => {
        console.log("Error:", error)
        setPDWLoading(false)
    });

    // get KPDSB Virtual Career Fair
    if (userType === 'Team' || (userInfo["Cluster"] && Array.isArray(userInfo["Cluster"]) && userInfo["Cluster"].includes("recFTIgJRWDh0JgXu"))) {
        try {
            setVCFLoading(true); // Assuming you want to show loading before fetching
            const res = await airtable.sessions.getSessionsByTag("Virtual Career Fair");
            setVCFSessions(res); // Directly use 'res' if it's already in the desired format
        } catch (error) {
            console.log("Error:", error);
        } finally {
            setVCFLoading(false); // Ensure loading is hidden afterwards
        }
    }

    // get RRDSB Indigenous
    if (userType === 'Team' || (userInfo["Cluster"] && Array.isArray(userInfo["Cluster"]) && userInfo["Cluster"].includes("rec8Vx2F8H3fnZyBe"))) {
        try {
            setRRDSBILoading(true); // Assuming you want to show loading before fetching
            const res = await airtable.sessions.getSessionsByTag("RRDSB Indigenous Spring 2024");
            setRRDSBISessions(res); // Directly use 'res' if it's already in the desired format
        } catch (error) {
            console.log("Error:", error);
        } finally {
            setRRDSBILoading(false); // Ensure loading is hidden afterwards
        }
    }
    */

  }

  useEffect(() => {
    if (sLoading || pLoading || initialSetupDone) return;
    const ss = [...studentSessions, ...professionalSessions].filter((session) => session.Teacher && session.Teacher.indexOf(userInfo.id) >= 0);
    const hash = location.hash.slice(1);  // Remove the '#' at the start
    if (!ss || ss.length === 0) {
      if (!initSSLoaded && studentSessions && studentSessions.length) setActiveTab("student")
      else if (professionalSessions && professionalSessions.length) setActiveTab("professional")
    }
    if (hash && ['registered', 'student', 'professional', 'pdweek', 'vcf', 'rrdsbi'].includes(hash)) setActiveTab(hash);

    setRegisteredSessions(ss);
    setRLoading(false);
    setInitialSetupDone(true);
  }, [studentSessions, professionalSessions/*, pdweekSessions*/, userInfo, sLoading, pLoading, location.hash]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="main-container">
      <h1>{t("events")}</h1>
      <p>
        {t("eventspage.description")}{" "}
        <a href="/cn/search?credits=0" style={{ textDecoration: 'underline' }}>
          {t("eventspage.exploreFreeSessions")}
        </a>
      </p>
      <div style={{ marginTop: 10 }}>
        <Nav tabs className="upcoming-tabs" style={{ marginBottom: 10 }}>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'registered' })}
              onClick={() => setActiveTab('registered')}
            >
              {t('registered')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'student' })}
              onClick={() => setActiveTab('student')}
            >
              {t('student-events')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === 'professional' })}
              onClick={() => setActiveTab('professional')}
            >
              {t('professional-learning')}
            </NavLink>
          </NavItem>
          {/* (userType === 'Team' || (userInfo["Cluster"] && Array.isArray(userInfo["Cluster"]) && userInfo["Cluster"].includes("rec8Vx2F8H3fnZyBe"))) &&
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'rrdsbi' })}
                                onClick={() => setActiveTab('rrdsbi')}
                            >
                                RRDSB Indigenous Showcase
                            </NavLink>
                        </NavItem>
                    */}
          {/* (userType === 'Team' || (userInfo["Cluster"] && Array.isArray(userInfo["Cluster"]) && userInfo["Cluster"].includes("recFTIgJRWDh0JgXu"))) &&
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'vcf' })}
                                onClick={() => setActiveTab('vcf')}
                            >
                                Virtual Career Fair
                            </NavLink>
                        </NavItem>
                    */ }
          {/* (userType === 'Team' || (userInfo["School PT"] && userInfo["School PT"][0] === "recalRmA0o3OZhdlO")) &&
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === 'pdweek' })}
                                onClick={() => setActiveTab('pdweek')}
                            >
                                NU PD Week 2024
                            </NavLink>
                        </NavItem>
                    */}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="registered">
            <SessionList
              sessions={registeredSessions}
              loading={rLoading}
              updateProcessing={setProcessing}
              updateSessions={setRegisteredSessions}
              refresh={refreshPage}
            />
          </TabPane>
          <TabPane tabId="student">
            <SessionList
              sessions={studentSessions}
              loading={sLoading}
              updateProcessing={setProcessing}
              updateSessions={setStudentSessions}
              refresh={refreshPage}
            />
          </TabPane>
          <TabPane tabId="professional">
            <SessionList
              sessions={professionalSessions}
              loading={pLoading}
              updateProcessing={setProcessing}
              updateSessions={setProfessionalSessions}
              refresh={refreshPage}
            />
          </TabPane>
          {/* (userType === 'Team' || (userInfo["Cluster"] && Array.isArray(userInfo["Cluster"]) && userInfo["Cluster"].includes("rec8Vx2F8H3fnZyBe"))) &&
                        <TabPane tabId="rrdsbi">
                            <SessionList
                                sessions={rrdsbiSessions}
                                loading={rrdsbiLoading}
                                updateProcessing={setProcessing}
                                updateSessions={setRRDSBISessions}
                                groupBy='day'
                                askable
                                refresh={refreshPage}
                            />
                        </TabPane>
                    */}
          {/* (userType === 'Team' || (userInfo["Cluster"] && Array.isArray(userInfo["Cluster"]) && userInfo["Cluster"].includes("recFTIgJRWDh0JgXu"))) &&
                        <TabPane tabId="vcf">
                            <SessionList
                                sessions={vcfSessions}
                                loading={vcfLoading}
                                updateProcessing={setProcessing}
                                updateSessions={setVCFSessions}
                                groupBy='day'
                                askable
                                refresh={refreshPage}
                            />
                        </TabPane>
                    */}
          {/* (userType === 'Team' || (userInfo["School PT"] && userInfo["School PT"][0] === "recalRmA0o3OZhdlO")) &&
                        <TabPane tabId="pdweek">
                            <SessionList
                                sessions={pdweekSessions}
                                loading={pdwLoading}
                                updateProcessing={setProcessing}
                                updateSessions={setPDWeekSessions}
                                groupBy='day'
                                askable
                                refresh={refreshPage}
                            />
                        </TabPane>
                    */}
        </TabContent>
      </div>
      <OverlayLoading visible={processing} title="Registering..." />
    </div>
  )
}

export default Screen;
