import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import airtable from '../../../../airtables';
import { setAppUserInfo } from '../../../../redux/actions';
import Loading from '../../../../components/Loading';
import { checkBestPractices } from '../utils';
import BP2024ProgressBar from '../components/BP2024ProgressBar';

const BPReflection = () => {
  const { userInfo, userType } = useSelector(state => state.appInfo);
  const [reflectionIW, setReflectionIW] = useState("");
  const [reflectionTI, setReflectionTI] = useState("");
  const [reflectionI, setReflectionI] = useState("");
  const [reflectionRUC, setReflectionRUC] = useState("");
  const [saving, setSaving] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    window.document.title = "Reflection - Best Practices - Connected North"
  }, [])

  useEffect(() => {
    const redir = checkBestPractices(userInfo, userType, "reflection");
    if (redir) window.location.href = `/cn${redir}`;
  }, [userInfo, userType])

  const canGoNext = () => {
    if (userInfo["Renewal Actions 24-25"].includes("Indigenous Worldviews Reflection") && !reflectionIW) return false;
    if (userInfo["Renewal Actions 24-25"].includes("Trauma-Informed Reflection") && !reflectionTI) return false;
    if (userInfo["Renewal Actions 24-25"].includes("Interactivity Reflection") && !reflectionI) return false;
    if (userInfo["Renewal Actions 24-25"].includes("Refreshing & Updating Content") && !reflectionRUC) return false;
    return true;
  }

  const updateBestPractices = async () => {
    setSaving(true);
    try {
      await airtable.providers.update(userInfo.id, {
        "ReflectionIW": reflectionIW,
        "ReflectionTI": reflectionTI,
        "ReflectionI": reflectionI,
        "ReflectionRUC": reflectionRUC,
        "2024 Reflection Completed": true
      });
      const ui = await airtable.providers.select(userInfo.id);
      dispatch(setAppUserInfo(ui));
    } catch (error) {
      toast.error(error.toString())
    } finally {
      setSaving(false);
    }
  }

  const renderContentIW = () => {
    if (!userInfo["Renewal Actions 24-25"].includes("Indigenous Worldviews Reflection")) return null;

    return (
      <div className="reflection-item-content">
        <h4>{t("reflection.iw.title")}</h4>
        <p>
          <Trans
            i18nKey="reflection.iw.p1"
            components={{ b: <b /> }}
          />
        </p>
        <p>
          <Trans
            i18nKey="reflection.iw.p2"
            components={{
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              a: <a href="https://sessions.connectednorth.org/cn/resources#Creatively%20Customized%20Content" target="_blank" rel="noreferrer" />
            }}
          />
        </p>
        <p>{t("reflection.iw.p3")}</p>
        <ul>
          <li><p>{t("reflection.iw.p3-li1")}</p></li>
          <li><p>{t("reflection.iw.p3-li2")}</p></li>
        </ul>

        <div style={{ marginTop: 30 }}>
          <p>{t("reflection.iw.question")}</p>
          <Input
            type="textarea"
            rows={6}
            disabled={saving}
            onChange={e => setReflectionIW(e.target.value)}
            value={reflectionIW}
          />
        </div>
      </div>
    )
  }

  const renderContentTI = () => {
    if (!userInfo["Renewal Actions 24-25"].includes("Trauma-Informed Reflection")) return null;
    return (
      <div className="reflection-item-content">
        <h4>{t("reflection.ti.title")}</h4>
        <p>{t("reflection.ti.p1")}</p>
        <p>
          <Trans
            i18nKey="reflection.ti.p2"
            components={{
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              a: <a href="https://sessions.connectednorth.org/cn/resources#Creatively%20Customized%20Content" target="_blank" rel="noreferrer" />
            }}
          />
        </p>
        <p>{t("reflection.ti.p3")}</p>
        <ul>
          <li><p>{t("reflection.ti.p3-li1")}</p></li>
          <li><p>{t("reflection.ti.p3-li2")}</p></li>
        </ul>

        <div style={{ marginTop: 30 }}>
          <p>{t("reflection.ti.question")}</p>
          <Input
            type="textarea"
            rows={6}
            disabled={saving}
            onChange={e => setReflectionTI(e.target.value)}
            value={reflectionTI}
          />
        </div>
      </div>
    )
  }

  const renderContentI = () => {
    if (!userInfo["Renewal Actions 24-25"].includes("Interactivity Reflection")) return null;
    return (
      <div className="reflection-item-content">
        <h4>{t("reflection.i.title")}</h4>
        <p>{t("reflection.i.p1")}</p>
        <p>
          <Trans
            i18nKey="reflection.i.p2"
            components={{
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              a: <a href="https://sessions.connectednorth.org/cn/resources#Creatively%20Customized%20Content" target="_blank" rel="noreferrer" />
            }}
          />
        </p>
        <p>{t("reflection.i.p3")}</p>
        <ul>
          <li><p>{t("reflection.i.p3-li1")}</p></li>
          <li><p>{t("reflection.i.p3-li2")}</p></li>
        </ul>

        <div style={{ marginTop: 30 }}>
          <p>{t("reflection.i.question")}</p>
          <Input
            type="textarea"
            rows={6}
            disabled={saving}
            onChange={e => setReflectionI(e.target.value)}
            value={reflectionI}
          />
        </div>
      </div>
    )
  }

  const renderContentRUC = () => {
    if (!userInfo["Renewal Actions 24-25"].includes("Refreshing & Updating Content")) return null;
    return (
      <div className="reflection-item-content">
        <h4>{t("reflection.ruc.title")}</h4>
        <div style={{ marginTop: 10 }}>
          <p>{t("reflection.ruc.question")}</p>
          <Input
            type="textarea"
            rows={6}
            disabled={saving}
            onChange={e => setReflectionRUC(e.target.value)}
            value={reflectionRUC}
          />
        </div>
      </div>
    )
  }

  if (!userInfo["Renewal Actions 24-25"]) return null;

  return (
    <div className="main-container best-practices">
      <BP2024ProgressBar current="reflection" />
      <div style={{ height: 20 }} />
      <h1>{t("bestPractices.reflection.title")}</h1>
      <div className="main-content">
        {renderContentIW()}
        {renderContentTI()}
        {renderContentI()}
        {renderContentRUC()}

        <div align="center" style={{ marginTop: 20 }}>
          <Button
            color="primary"
            size="md"
            type="button"
            disabled={saving || !canGoNext()}
            onClick={() => updateBestPractices()}
          >{saving && <Loading size={14} />}&nbsp;{t("reflection.next-btn")}</Button>
        </div>
      </div>
    </div>
  )
}

export default BPReflection;
